import styled from "styled-components";
import { Flex } from "src/components/ui";
// import close from 'src/assets/images/common/close.svg';
import phone from "src/assets/images/profile/phone.svg";
import mail from "src/assets/images/profile/mail.svg";
import chat from "src/assets/images/profile/chat.svg";
import { FC, useState } from "react";
import SendMailModal from "../users/SendMailModal";
import { SendNotificationModal } from "../users/SendNotificationModal";
import ChangeWithdrawalBankAccount from "../users/ChangeWithdrawalBankModel";
import avatar from "../../assets/images/header/avatar.svg";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { FiChevronUp } from "react-icons/fi";
import { humanReadableDate } from "src/utils";
import { Button, ButtonClass } from "../ui";
import userServices from "src/service/userServices";
import { useLoading } from "src/hooks";
import { toast } from "react-toastify";
import close from "src/assets/images/common/close.svg";
import { ClipLoader } from "react-spinners";
import { DeleteOutline } from "@mui/icons-material";

export const tierHandler = (tier: number) => {
	// console.log(typeof tier);
	switch (tier) {
		case 1:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier1.svg' alt='tier 1' />
				</div>
			);
		case 2:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier2.svg' alt='tier 2' />
				</div>
			);
		case 3:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier3.svg' alt='tier 3' />
				</div>
			);
		case 4:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier4.svg' alt='tier 4' />
				</div>
			);
		case 5:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier5.svg' alt='tier 5' />
				</div>
			);
		case 6:
			return (
				<div className='flex gap-1 items-center'>
					<p>{tier}</p>
					<img src='/svgs/tier6.svg' alt='tier 6' />
				</div>
			);

		default:
			break;
	}
};

export const ProfileInfoContainer = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	margin: 3rem 0;
	border-radius: 16px;
	padding: 20px;
	.heading {
		margin-bottom: 3rem;
		.title {
			font-weight: 700;
			font-size: 18px;
			line-height: 28px;
			color: ${(props) => props.theme.colors.text_01};
		}
	}

	.profile-info--lhs {
		.dp {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			object-fit: cover;
		}
	}
	.profile-info--rhs {
		.details {
			.text {
				font-size: 16px;
				color: ${(props) => props.theme.colors.text_01};
				padding-bottom: 7px;
				font-weight: 600;
			}
			.key {
				font-weight: 400;
				padding-right: 100px;
			}
			.status-action {
				font-size: 14px;
				border-radius: 32px;
				display: flex;
				justify-content: center;
				width: max-content;
				padding: 6px 12px;
			}
		}
	}
`;

export const ProfileInfo: FC<{ userDetails: { [key: string]: any } }> = ({
	userDetails,
}) => {
	const [openSendNotificationModal, setOpenSendNotificationModal] =
		useState(false);
	const handleOpenNotificationModal = () => setOpenSendNotificationModal(true);
	const handleCloseNotificationModal = () =>
		setOpenSendNotificationModal(false);

	const [openSendMailModal, setOpenSendMailModal] = useState(false);
	const handleOpenMailModal = () => setOpenSendMailModal(true);
	const handleCloseMailModal = () => setOpenSendMailModal(false);

	const [openChangeBankModal, setOpenChangeBankModal] = useState(false);
	const toggleChangeBankModal = () => {
		setOpenChangeBankModal(() => !openChangeBankModal);
	};
	const { loading, startLoading, stopLoading } = useLoading();
	const {
		loading: undeleteUser,
		startLoading: startUndeletingUsers,
		stopLoading: stopUndeletingUsers,
	} = useLoading(false);

	const updateUserDeleted = () => {
		startUndeletingUsers();
		userServices
			.updateUser(userDetails._id || "", {
				deleted: "Yes",
			})
			.then((res) => {
				toast.success(res?.data?.message);
				window.location.reload();
			})
			.catch((err) => {
				// console.log(err.response);
				toast.error(err?.response?.data?.error?.message);
			})
			.finally(() => stopUndeletingUsers());
	};
	const suspendUser = () => {
		startLoading();
		if (!userDetails.user.suspended) {
			console.log(userDetails);

			userServices
				.suspendUser(userDetails.user._id || "", {
					reason: "Low rating and complaint raised by a customer",
				})
				.then((res) => {
					toast.success(res?.data?.message);
					window.location.reload();
				})
				.catch((err) => {
					toast.error(err?.response?.data?.error?.message);
				})
				.finally(() => stopLoading());
		} else {
			console.log(userDetails);
			userServices
				.unSuspendUser(userDetails.user._id || "", {
					reason: "Low rating and complaint raised by a customer",
				})
				.then((res) => {
					toast.success(res?.data?.message);
					window.location.reload();
				})
				.catch((err) => {
					console.log(err.response);
					window.location.reload();
				})
				.finally(() => stopLoading());
		}
	};

	return (
		<ProfileInfoContainer>
			<div className='heading'>
				<Flex justify='space-between' align='center' wrap='wrap'>
					<h1 className='font-semibold text-2xl'>Profile Information</h1>
					<div>
						<Flex>
							<Button
								onClick={() => {
									suspendUser();
								}}
								classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
								style={{
									width: "max-content",
									backgroundColor: userDetails?.user?.suspended
										? "#00CCCD"
										: "#EB5656",
								}}
								disabled={loading}
							>
								{loading ? (
									<ClipLoader size={20} />
								) : (
									<>
										{!userDetails?.user?.suspended && (
											<img src={close} alt='x' />
										)}
										<span>
											{userDetails?.user?.suspended
												? "Activate Account"
												: "Deactivate Account"}
										</span>
									</>
								)}
							</Button>
							{userDetails.deleted && (
								<Button
									onClick={() => {
										updateUserDeleted();
									}}
									classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
									style={{
										backgroundColor: "#00CCCD",
									}}
									disabled={undeleteUser}
								>
									{undeleteUser ? (
										<ClipLoader size={20} />
									) : (
										<>
											<span>Undelete Account</span>
										</>
									)}
								</Button>
							)}
						</Flex>
					</div>
				</Flex>
			</div>
			<SendMailModal
				open={openSendMailModal}
				userEmail={userDetails?.user?.email || ""}
				handleClose={handleCloseMailModal}
				user={userDetails?.user?.first_name || ""}
			/>
			<SendNotificationModal
				artisan={false}
				userId={[userDetails?.user?._id]}
				open={openSendNotificationModal}
				handleClose={handleCloseNotificationModal}
			/>
			<ChangeWithdrawalBankAccount
				open={openChangeBankModal}
				artisan={false}
				handleClose={toggleChangeBankModal}
				withdrawalBank={userDetails?.withdrawal_bank}
				userId={userDetails?.user?._id}
			/>
			<Flex gap='2rem'>
				<div className='profile-info--lhs'>
					<Flex direction='column' align='center' gap='1.5rem'>
						<div className='relative'>
							<span className='absolute right-0 cursor-pointer h-10 w-10 bg-red-50 rounded-full flex items-center justify-center'>
								<DeleteOutline color='error' />
							</span>

							<img
								src={userDetails?.user?.display_picture || avatar}
								alt='dp'
								className='dp'
							/>
						</div>
						<Flex gap='1.5rem'>
							<a href='tel:+2348110658901'>
								<img src={phone} alt='phone' />
							</a>
							<button
								style={{ cursor: "pointer" }}
								onClick={() => handleOpenMailModal()}
							>
								<img src={mail} alt='mail' />
							</button>

							<button
								style={{ cursor: "pointer" }}
								onClick={handleOpenNotificationModal}
							>
								<img src={chat} alt='chat' />
							</button>
						</Flex>
						<Link
							to={`/verification/customer/${userDetails?.user?._id}/face-recognition`}
							className=' bg-primary py-3 px-2 2xl:px-5 text-white rounded-lg'
						>
							View Verification
						</Link>
					</Flex>
				</div>
				<div className='profile-info--rhs'>
					<Flex direction='column' gap='1.5rem'>
						<h3 className='text-xl font-semibold'>
							{userDetails?.user?.first_name || ""}{" "}
							{userDetails?.user?.last_name || ""}
						</h3>
						<div className='details'>
							<table>
								<tbody>
									<tr>
										<td className='text key'>Email</td>
										<td className='text value'>
											{userDetails?.user?.email || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Phone number</td>
										<td className='text value'>
											{userDetails?.user?.phone || ""}
										</td>
									</tr>
									<tr className=''>
										<td className='text key'>Address</td>
										<td>
											<div className='rounded-2xl bg-white'>
												{/* {userDetails?.address?.[0]?.house_address || ''} */}
												<Disclosure>
													{({ open }) => (
														<>
															<Disclosure.Button className='flex gap-4 w-full text-left text-sm font-semibold text-[#4D4D4D] '>
																<p className='flex gap-1 items-center'>
																	<span className=' text-[#7E00C4]'>
																		(Default)
																	</span>
																	<span className=''>
																		{userDetails?.address?.[0]?.house_address ||
																			""}
																	</span>
																</p>
																<FiChevronUp
																	className={`${
																		open ? "" : "rotate-180 transform"
																	} h-5 w-5 text-[#999999]`}
																/>
															</Disclosure.Button>
															<Disclosure.Panel className='px-4 py-2 text-sm bg-[#F9FAFB] rounded-lg text-gray-500'>
																{userDetails?.address?.map((item: any) => (
																	<p className='text-[#4D4D4D]'>
																		{item?.house_address}
																	</p>
																))}
															</Disclosure.Panel>
														</>
													)}
												</Disclosure>
											</div>
										</td>
									</tr>
									<tr>
										<td className='text key'>City</td>
										<td className='text value'>
											{userDetails?.address?.[0].city || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>State</td>
										<td className='text value'>
											{userDetails?.address?.[0]?.state || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Rating</td>
										<td className='text value flex items-center gap-1'>
											<p>{userDetails?.user?.rating || ""}</p>
											<img src='/svgs/star.svg' alt='' className='w-4 h-4' />
										</td>
									</tr>
									<tr>
										<td className='text key'>Tier</td>
										<td className='text value'>
											{tierHandler(userDetails?.user?.tier)}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account State</td>
										<td className='text value'>
											{userDetails?.user?.suspended ? "Suspended" : "Active"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Deleted</td>
										<td className='text value'>
											{userDetails?.user?.deleted ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Can Edit Profile</td>
										<td className='text value'>
											{userDetails?.user?.can_edit_profile ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Can Withdraw</td>
										<td className='text value'>
											{userDetails?.user?.can_withdraw ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Creation Date</td>
										<td className='text value'>
											{userDetails?.user?.createdAt &&
												humanReadableDate(
													new Date(userDetails?.user?.createdAt)
												)}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Last Update Date</td>
										<td className='text value'>
											{userDetails?.user?.updatedAt &&
												humanReadableDate(
													new Date(userDetails?.user?.updatedAt)
												)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='flex flex-row justify-between'>
							<h3 className='text-xl font-semibold'>Withdrawal Wallet</h3>
							<button
								className='flex items-center gap-4'
								onClick={toggleChangeBankModal}
							>
								<svg
									stroke='currentColor'
									fill='currentColor'
									stroke-width='0'
									viewBox='0 0 24 24'
									height='15'
									width='15'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g id='Edit'>
										<g>
											<path d='M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z'></path>
											<path d='M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z'></path>
										</g>
									</g>
								</svg>
								<span className='text key'>Change Withdrawal Account</span>
							</button>
						</div>
						<div className='details'>
							<table>
								<tbody>
									<tr>
										<td className='text key'>Account Name</td>
										<td className='text value'>
											{userDetails?.withdrawal_bank?.account_name || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Number</td>
										<td className='text value'>
											{userDetails?.withdrawal_bank?.account_number || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Bank Name</td>
										<td className='text value'>
											{userDetails?.withdrawal_bank?.bank_name || ""}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Flex>
				</div>
			</Flex>
		</ProfileInfoContainer>
	);
};

export default ProfileInfo;
