import ArtisanTable from "src/components/artisan/ArtisanTable";
import ArtisanStaleTable from "src/components/artisan/ArtisanStaleTable";
import { auth } from "src/store/Auth";
import { DashboardLayout } from "src/components/dashboard";
import { Input } from "src/components/inputs";
import searchIcon from "src/assets/images/input/searchIcon.svg";
import { useEffect, useState } from "react";
import { useLoading, useExportData } from "src/hooks";
import { ArtisansServices } from "src/service/ArtisansServices";
import { Loader } from "src/components/common";
import { toast, ToastContainer } from "react-toastify";
import { Button, ButtonClass, Flex } from "src/components/ui";
import { theme } from "src/styles/Theme";
import { SendNotificationModal } from "src/components/users";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";

interface Props {
  handleChangeSearch: (e: any) => void;
  handleStale: (e: any) => void;
  handleActive: (e: any) => void;
  handleOpen: (e: any) => void;
  handleExport: () => void;
  staleTableBtn: boolean
}

export default function Artisan() {
  const [searchField, setSearchField] = useState("");
  const [allArtisans, setAllArtisans] = useState([]);
  const [allStaleArtisans, setAllStaleArtisans] = useState([]);
  const { loading, startLoading, stopLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showStaleAccount, setShowStaleAccount] = useState(false);
  const [staleTableBtn, setStaleTableBtn] = useState(true)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    document.title = "Vendor's Page";
  }, []);


  useEffect(() => {
    fetchAllArtisans();
  }, []);

  const fetchAllArtisans = () => {
    startLoading();
    ArtisansServices.getAllArtisans()
      .then((res) => {
        // console.log(res?.data?.payload?.data);
        setAllArtisans(res?.data?.payload?.data);
      })
      .catch((err: any) => {
        // console.log(err?.response?.data?.error?.message);
        toast.error(err?.response?.data?.error?.message);
      })
      .finally(() => stopLoading());
  };

  const fetchAllStaleArtisans = () => {
    startLoading();
    ArtisansServices.getAllStaleArtisans()
      .then((res) => {
        // console.log(res?.data?.payload?.data);
        setAllStaleArtisans(res?.data?.payload?.data?.artisans);
      })
      .catch((err: any) => {
        // console.log(err?.response?.data?.error?.message);
        toast.error(err?.response?.data?.error?.message);
      })
      .finally(() => stopLoading());
  };

  const handleStale = () => {
    setShowStaleAccount(true)
    setStaleTableBtn(false)
    fetchAllStaleArtisans()
  }
  const handleActive = () => {
    setShowStaleAccount(false)
    setStaleTableBtn(true)
    fetchAllArtisans()
  }


  const filteredData = allArtisans.filter((data: any) => {
    return (
      data?.first_name?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.last_name?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.email?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.occupation?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.gender?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.phone?.toLowerCase().includes(searchField.toLowerCase())
    );
  });

    const filteredStaleData = allStaleArtisans.filter((data: any) => {
    return (
      data?.first_name?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.last_name?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.email?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.occupation?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.gender?.toLowerCase().includes(searchField.toLowerCase()) ||
      data?.phone?.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const handleChangeSearch = (e: any) => {
    setSearchField(e.target.value);
  };

  //Exporting data to csv format
  const artisansHeaders = [
    "First name",
    "Last name",
    "Email",
    "Status",
    "Suspended",
    "Gender",
    "Occupation",
    "Phone"
  ];
  const DATA_TO_EXPORT = allArtisans.map((item: any) => {
    return {
      first_name: `${item?.first_name}`,
      last_name: `${item.last_name}`,
      email: `${item?.email}`,
      status: item?.status,
      suspended: item?.suspended,
      gender: `${item?.gender}`,
      occupation: `${item?.occupation}`,
      phone: `${item?.phone}`
    };
  });
  // const getExportedData = useExportData(DATA_TO_EXPORT, artisansHeaders);

  // function handleExportedData() {
  //   getExportedData();
  //   setShowExportModal(false);
  // }
  const {
    loading: exportingData,
    startLoading: startExportingData,
    stopLoading: stopExportingData,
  } = useLoading(false);
  
  const dumpArtisanData = () => {
    startExportingData()
    ArtisansServices.DumpArtisansData()
    .then((res) => {
      stopExportingData();
      setShowExportModal(false);
      toast.success(res?.data?.message)
      window.location.href = res?.data?.payload?.data?.fileUrl
    })
    .catch((err) => {
      console.log('error');
    })
  }

  return (
    <DashboardLayout
      pageTitle="Vendor"
      rhsHeading={
        <RhsHeading
          handleChangeSearch={handleChangeSearch}
          handleOpen={handleOpen}
          handleStale={handleStale}
          handleActive={handleActive}
          staleTableBtn = {staleTableBtn}
          handleExport={() => setShowExportModal(!showExportModal)}
        />
      }
    >
      <SendNotificationModal
        open={open}
        userId={[""]}
        handleClose={handleClose}
        artisan={true}
      />
      <ToastContainer />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <Loader>loading...</Loader>{" "}
        </div>
      ) :  showStaleAccount ? (
         <ArtisanStaleTable filteredRow={filteredStaleData} />
      ) : filteredData.length > 0 ? (
        <ArtisanTable filteredRow={filteredData} />
      ) :(
        <p className="table-entry-status">No Vendor Found</p>
      )}

      <AppModal
        open={showExportModal}
        onClose={() => setShowExportModal(false)}
        content={
          <ModalContent
            content1="Export Artisans Data"
            content2="Are you sure you want to export artisans data?"
            btnAction={dumpArtisanData}
            linkContent={"Yes, Please"}
            onClick={() => setShowExportModal(false)}
            loading={exportingData}
          />
        }
      />
    </DashboardLayout>
  );
}

export const RhsHeading: React.FC<Props> = ({
  handleChangeSearch,
  handleOpen,
  handleExport,
  handleStale,
  handleActive,
  staleTableBtn,
}) => {
    const [isOccupManager, setIsOccupManager] = useState(false)

  const { authUser } = auth.use();
  useEffect(() => {
    if(authUser?.role?.includes('occupationalManager')){
      setIsOccupManager(true)
    }
  }, [authUser]);
  return (
    <Flex wrap="wrap">
      <Button
        onClick={handleExport}
        classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
        style={{
          backgroundColor: theme.colors.purple,
          height: "48px",
          minWidth: "145px",
        }}
        className={`${isOccupManager ? '!hidden' : ''}`}
      >
        <span>Export</span>
      </Button>
      <Button
        onClick={handleOpen}
        classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
        style={{
          backgroundColor: theme.colors.purple,
          height: "48px",
          width: "max-content",
        }}
        className={`${isOccupManager ? '!hidden' : ''}`}
      >
        <span>Notify all artisans</span>
      </Button>
      <Input
        icon={<img src={searchIcon} alt="searchIcon" />}
        type="search"
        placeholder="Search"
        handleChange={handleChangeSearch}
      />
      
      <Button
        onClick={handleActive}
        classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
        style={{
          backgroundColor: '#eb5656',
          height: "48px",
          width: "max-content",
        }}
        className={`${isOccupManager ? '!hidden' : ''}`}
        disabled={staleTableBtn}
      >
        <span>Active Accounts</span>
      </Button>
      <Button
        onClick={handleStale}
        classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
        style={{
          backgroundColor: '#210639',
          height: "48px",
          width: "max-content",
        }}
        className={`${isOccupManager ? '!hidden' : ''}`} 
        disabled={!staleTableBtn}
      >
        <span>Stale Accounts</span>
      </Button>
    </Flex>
  );
};
