import { useState, useEffect, ChangeEvent, KeyboardEventHandler } from "react";
import Select, { ActionMeta, OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Input } from "src/styles/commonStyle";
import { Button, ButtonClass, Flex } from "src/components/ui";

import { formatDateYmd } from "src/utils/helpers";
import { theme } from "src/styles/Theme";
import useLoading from "src/hooks/useLoading";
import userServices from "src/service/userServices";
import miscService from "src/service/miscServices";

// import { Controller, useForm } from "react-hook-form";
// import { NumberInput } from "../inputs/NumberInput";

const ProfileContainer = styled.div`
  padding: 40px;
  background: #ffffff;
  width: 100%;
  margin-top: 36px;
  border-radius: 16px;
  h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .input_wrapper {
    display: grid;
    grid: auto / auto auto;
    gap: 20px;
  }
`;

const styles = {
  control: (styles: any, { isFocused }: { isFocused: any }) => ({
    ...styles,
    backgroundColor: isFocused ? "white" : "transparent",
    padding: "0 14px",
    width: "100%",
    minHeight: "48px",
    outline: "none",
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    border: "1px solid #98a2b3",
  }),
  option: (styles: any, { isSelected }: { isSelected: any }) => ({
    ...styles,
    fontWeight: isSelected ? "bold" : "normal",
    color: isSelected ? "white" : "black",
    backgroundColor: isSelected ? "rgba(102, 198, 236, 0.897)" : "white",
    fontSize: "inherit",
  }),
};

interface Props {
  verifyData: any;
}

interface UserType {
  first_name: string;
  last_name: string;
  interests: string[];
  dob: string;
  gender: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  can_edit_profile: 'yes' | 'no ';
  can_withdraw: 'yes' | 'no ';
  paid_verification_fee?: boolean;
}

interface InterestType {
  label: string;
  value: string;
}

interface OccupationTypes {
  name: string;
}

const CustomerProfileInfo = ({ verifyData }: Props) => {
  const [userData, setUserData] = useState<UserType>({
    first_name: verifyData?.user?.first_name,
    last_name: verifyData?.user?.last_name,
    interests: verifyData?.user?.interests,
    dob: verifyData?.user?.dob,
    gender: verifyData?.user?.gender,
    email: verifyData?.user?.email,
    phone: verifyData?.user?.phone,
    address: verifyData?.address?.house_address,
    city: verifyData?.address?.city,
    state: verifyData?.address?.state,
    can_edit_profile: verifyData?.user?.can_edit_profile,
    can_withdraw: verifyData?.user?.can_withdraw,
    paid_verification_fee: verifyData?.user?.meta?.paid_verification_fee
  });
  const [interestOptions, setInterestOptions] = useState<InterestType[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { user_id } = useParams();

  useEffect(() => {
    if (verifyData) {
      const sortedInterestOptions = verifyData?.user?.interests.map(
        (item: any) => {
          return {
            label: item,
            value: item,
          };
        }
      );

      if (sortedInterestOptions?.length > 0) {
        setInterestOptions([...sortedInterestOptions]);
        // console.log(sortedInterestOptions);
      }
    }
    // setInterestptions([]);
  }, []);

  const [occupations, setOccupations] = useState<OccupationTypes[]>([]);
  useEffect(() => {
		miscService
			.getOccupations()
			.then((res) => {
				// console.log(res?.data?.payload);
        const interests = res?.data?.payload.data.map((item: any) => item.name)
				setOccupations(res?.data?.payload.data);
			})
			.catch((err: any) => {
				console.log(err.response.data.error.message);
			});
	}, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  }

  function createOption(label: string) {
    return {
      label,
      value: label.toLowerCase(),
    };
  }

  const onChange = (
    newValue: OnChangeValue<InterestType, true>,
    actionMeta: ActionMeta<InterestType>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        newValue = interestOptions.filter(
          (item) => item.label !== actionMeta.removedValue.label
        );
        setInterestOptions([...newValue]);
        // console.log(interestOptions);

        break;
      case "create-option":
        setInterestOptions((prev) => [
          ...prev,
          createOption(actionMeta.option.label),
        ]);
        // console.log(interestOptions);

        break;
      case "clear":
        newValue = [];
        setInterestOptions([...newValue]);

        break;
    }
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			target: { value, name },
		} = event;
    setUserData((prev) => ({ ...prev, [name]: value }));
	};

  const handleKeyDown: KeyboardEventHandler = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInterestOptions((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const {
    loading: updatingUser,
    startLoading: startUpdatingUser,
    stopLoading: stopUpdatingUser,
  } = useLoading();

  function handleUpdateUser(e: any) {
    e.preventDefault();

    const newInterests = interestOptions.map((item) => item.value);

    const data: UserType = {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      interests: newInterests,
      dob: userData?.dob,
      gender: userData?.gender,
      email: userData?.email,
      phone: userData?.phone,
      address: userData?.address,
      city: userData?.city,
      state: userData?.state,
      can_edit_profile: userData?.can_edit_profile,
      can_withdraw: userData?.can_withdraw,
    };

    startUpdatingUser();
    user_id &&
      userServices
        .updateUser(user_id, data)
        .then((res) => {
          // console.log(res.data);
          toast.success(res?.data?.message || []);
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
        })
        .finally(() => {
          stopUpdatingUser();
        });
  }

  const {
    loading: chargeVerificationFeeLoading,
    startLoading: startChargeVerificationFeeLoading,
    stopLoading: stopChargeVerificationFeeLoading,
  } = useLoading();

  function chargeVerificationFee(e: any) {
    e.preventDefault();

    startChargeVerificationFeeLoading();

    user_id &&
      userServices.chargeVerificationFee(user_id)
        .then((res) => {
          // console.log(res.data);
          toast.success(res?.data?.message || '');
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
        })
        .finally(() => {
          stopChargeVerificationFeeLoading();
        });
  }

  return (
    <ProfileContainer>
      <div className="flow-root">
        <div className="float-left">
         <h3 className="text-neutral text-3xl">Profile Information</h3>
        </div>
        <div className="float-right">
          { !userData?.paid_verification_fee && ( 
            <Button
              disabled={chargeVerificationFeeLoading}
              loading={chargeVerificationFeeLoading}
              classes={[ButtonClass.SOLID]}
              style={{ backgroundColor: theme.colors.purple }}
              onClick={chargeVerificationFee}
            >
              Charge Verification Fee
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${
            verifyData?.user?.display_picture || "/images/profilePics.png"
          })`,
          width: "153px",
          height: "153px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          objectFit: "cover",
          backgroundSize: "cover",
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          borderRadius: "50%",
        }}
      ></div>

      <br />
      <br />
      <form action="" onSubmit={handleUpdateUser}>
        {" "}
        <Flex direction="column" gap="2.5rem">
          <Flex>
            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">First Name</label>
              <Input
                name="first_name"
                style={{ background: "#F2F4F7" }}
                value={userData?.first_name}
                onChange={handleChange}
              />
            </Flex>

            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Last Name</label>
              <Input
                name="last_name"
                style={{ background: "#F2F4F7" }}
                value={userData?.last_name}
                onChange={handleChange}
              />
            </Flex>
          </Flex>
          <Flex>
            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Interests</label>

              <CreatableSelect
                isMulti
                styles={styles}
                isClearable
                onChange={onChange}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                // onCreateOption={handleCreate}
                defaultValue={interestOptions}
                options={interestOptions}
                value={interestOptions}
              />
            </Flex>

            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Date of birth</label>
              <Input
                type="date"
                style={{ background: "#F2F4F7" }}
                value={formatDateYmd(userData?.dob)}
                name="dob"
                onChange={handleChange}
              />
            </Flex>
          </Flex>

          <Flex>
            <Flex direction="column" style={{ width: "50%" }}>
                <label htmlFor="address">Address</label>
                <Input
                  name="address"
                  type="text"
                  style={{ background: "#F2F4F7" }}
                  value={userData.address}
                  onChange={handleChange}
                />
            </Flex>
            <Flex direction="column" style={{ width: "50%" }}>
                <label htmlFor="city">City</label>
                <Input
                  name="city"
                  type="text"
                  style={{ background: "#F2F4F7" }}
                  value={userData.city}
                  onChange={handleChange}
                />
            </Flex>
          </Flex>

          <Flex>
            <Flex direction="column" style={{ width: "50%" }}>
                <label htmlFor="state">State</label>
                <Input
                  name="state"
                  type="text"
                  style={{ background: "#F2F4F7" }}
                  value={userData.state}
                  onChange={handleChange}
                />
            </Flex>
            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Gender</label>
              <select
                id='gender'
                name='gender'
                placeholder='Select gender'
                onChange={handleSelectChange}
                style={{ background: "#F2F4F7" }}
                className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                value={userData?.gender}
              >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
              </select>
            </Flex>
          </Flex>
          <Flex>
            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Email</label>
              <Input
                style={{ background: "#F2F4F7" }}
                value={userData?.email}
                name="email"
                onChange={handleChange}
              />
            </Flex>
            <Flex direction="column" style={{ width: "50%" }}>
              <label htmlFor="first_name">Phone Number</label>
              <Input
                style={{ background: "#F2F4F7" }}
                value={userData?.phone}
                name="phone"
                onChange={handleChange}
              />
            </Flex>
          </Flex>
          <Flex>
            <Flex className="mt-8" direction="column" style={{ width: "50%" }}>
              <label htmlFor="can_edit_profile">Can Edit Profile</label>
              <select
                id='can_edit_profile'
                name='can_edit_profile'
                placeholder='Can Edit Profile'
                onChange={handleSelectChange}
                style={{ background: "#F2F4F7" }}
                className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                value={userData?.can_edit_profile ? 'Yes' : 'No'}
              >
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
              </select>
            </Flex>
            <Flex className="mt-8" direction="column" style={{ width: "50%" }}>
              <label htmlFor="can_withdraw">Can Withdraw</label>
              <select
                id='can_withdraw'
                name='can_withdraw'
                placeholder='Can Withdraw'
                onChange={handleSelectChange}
                style={{ background: "#F2F4F7" }}
                className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                value={userData?.can_withdraw ? 'Yes' : 'No'}
              >
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
              </select>
            </Flex>
          </Flex>
        </Flex>
        

        <div className="mt-[2.5rem]">
          <Button
            type="submit"
            disabled={updatingUser}
            loading={updatingUser}
            classes={[ButtonClass.SOLID]}
            style={{ backgroundColor: theme.colors.purple }}
          >
            Update Customer Data
          </Button>
        </div>
      </form>
    </ProfileContainer>
  );
};

export default CustomerProfileInfo;
